<template>
  <div class="door-lock-manage">
    <div class="main">
      <div class="lock">
        <div class="title">门锁列表</div>
        <div class="lock-list">
          <div class="lock-list-item" v-for="lock in lockList" :key="lock.id">
            <div class="lock-list-item__left">
              <i class="iconfont icon-mensuo iconStyle"></i>
              <span class="lock-name">{{ lock.alias }}</span>
              <i class="iconfont icon-dianchi iconStyle"></i>
              <span class="power">{{ lock.power }}%</span>
            </div>
            <div class="lock-list-item__right">
              <div class="lock-action-btn manage" @click="lockManageClick(lock.id)">管理</div>
              <div class="lock-action-btn del" @click="lockDelClick(lock)">删除</div>
            </div>
          </div>
        </div>
        <van-empty description="暂无数据" v-if="!lockList.length" />
      </div>
      <div class="order">
        <div class="title">工单列表</div>
        <div class="order-list">
          <div class="order-list-item" v-for="order in orderList" :key="order.id">
            <div class="order-list-item__top">
              <div class="time">{{ order.creationTime }}</div>
              <div class="status">{{ order.status | parseOrderStatus }}</div>
            </div>
            <div class="order-list-item__bottom">
              <div class="user">
                <div class="user-icon">
                  <van-icon name="manager" color="#fff" />
                </div>
                <span>{{ order.installPersonnelName }}</span>
              </div>
              <div class="del-btn" @click="orderDelClick(order.id)">删除</div>
            </div>
          </div>
        </div>
        <van-empty description="暂无数据" v-if="!orderList.length" />
      </div>
    </div>
    <div class="footer">
      <button class="new-order-btn" @click="addOrder">新建装锁工单</button>
      <van-popup v-model="showOrderDialog" class="order-form" @closed="closed" :close-on-click-overlay="false">
        <van-form @submit="onSubmit" ref="form">
          <van-field v-model="orderInfo.type" name="工单类型" label="工单类型" placeholder="用户名" :rules="[{ required: true, message: '请填写用户名' }]" />
          <van-field
            v-model="orderInfo.doorlockName"
            name="门锁名称"
            label="门锁名称"
            placeholder="请输入"
            :rules="[{ required: true, message: '门锁名称不能为空' }]"
          />
          <div class="order-action">
            <van-button class="order-action-btn" round block type="default" native-type="button" @click="showOrderDialog = false">取消</van-button>
            <van-button class="order-action-btn" round block type="info" native-type="submit">确认</van-button>
          </div>
        </van-form>
      </van-popup>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/classroom/doorLockManage.less";
import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";
import { cloneDeep } from "lodash-es";
export default {
  name: "DoorLockManage",
  data() {
    return {
      lockList: [],
      orderList: [],
      showOrderDialog: false,
      orderInfo: {
        type: "装锁",
        doorlockName: ""
      }
    };
  },
  methods: {
    addClassroomInstallOrderApi(classroomId, doorLockName) {
      let params = {
        classroomId,
        doorLockName
      };
      return this.$api.addClassroomInstallOrder({
        params
      });
    },
    deleteLockApi(lockId) {
      return this.$api.deleteLock({
        placeholder: {
          requestSource: "NB",
          lockId
        }
      });
    },
    setClassroomLockStatusApi(ids) {
      let params = {
        ids,
        status: "DELETED"
      };
      return this.$api.setClassroomLockStatus({
        params
      });
    },
    setClassroomInstallOrderStatusApi(ids) {
      let params = {
        ids,
        status: "DELETED"
      };
      return this.$api.setClassroomInstallOrderStatus({
        params
      });
    },
    getLockListApi(lockIds) {
      let params = {
        requestSource: "NB",
        lockIds: lockIds.toString()
      };
      return this.$api.getLockList({
        params
      });
    },
    getClassroomInstallOrderListApi(query) {
      let params = {
        query,
        page: false
      };
      return this.$api.getClassroomInstallOrderList({ params });
    },
    async getLockList() {
      let locks = sessionStorage.getItem("locks");
      locks = JSON.parse(locks);
      let lockIds = locks.map(item => item.lockId);
      let res = await this.getLockListApi(lockIds);
      if (res.code != SUCCESS_CODE) return;
      let data = res.data;
      data.forEach(item => {
        let lock = locks.find(lock => lock.lockId == item.id);
        lock && (item.logicId = lock.id);
      });
      this.lockList = res.data;
    },
    async getClassroomInstallOrderList() {
      let classroomId = this.$route.query.classroomId;
      let query = [
        { key: "classroomId", value: classroomId, oper: "equalTo" },
        { key: "status", value: "CANCELLED", oper: "approx" },
        { key: "status", value: "DELETED", oper: "approx" }
      ];
      query = this.getQuery(query);
      let res = await this.getClassroomInstallOrderListApi(query);
      if (res.code != SUCCESS_CODE) return;
      this.orderList = res.data;
    },
    getQuery(query) {
      // (&(xxx=xxx)(xxx=xxx))
      // [{key:xxx,value:xxx,oper:xxx}]
      if (query == [] || query == null) return null;
      let filters = query.reduce((prev, item) => {
        let filter = Filter.attribute(item.key)[item.oper](item.value);
        prev.push(filter);
        return prev;
      }, []);
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    lockManageClick(lockId) {
      this.$router.push({
        name: "doorLockDetail",
        query: {
          lockId
        }
      });
    },
    lockDelClick(lock) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          let p1 = this.deleteLockApi(lock.id);
          let p2 = this.setClassroomLockStatusApi([lock.logicId]);
          Promise.all([p1, p2]).then(res => {
            console.log(res);
            this.$utils.success("删除成功");
          });
        })
        .catch(() => {});
    },
    orderDelClick(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          let res = await this.setClassroomInstallOrderStatusApi([id]);
          if (res.code != SUCCESS_CODE) return;
          this.getClassroomInstallOrderList();
          this.$utils.success("删除成功");
        })
        .catch(() => {});
    },
    addOrder() {
      this.showOrderDialog = true;
    },
    async onSubmit() {
      let classroomId = this.$route.query.classroomId;
      let doorLockName = this.orderInfo.doorLockName;
      let res = await this.addClassroomInstallOrderApi(classroomId, doorLockName);
      if (res.code != SUCCESS_CODE) return;
      this.getClassroomInstallOrderList();
      this.showOrderDialog = false;
      this.$utils.success("新增成功");
    },
    closed() {
      this.orderInfo = cloneDeep(this.orderInfoCopy);
      this.$refs.form.resetValidation();
    },
    init() {
      this.orderInfoCopy = cloneDeep(this.orderInfo);
      this.getLockList();
      this.getClassroomInstallOrderList();
    }
  },
  filters: {
    parseOrderStatus(arg) {
      let status = {
        UNINSTALLED: "未安装",
        CANCELLED: "已取消",
        INSTALLED: "已安装",
        COMPLETED: "已完成"
      };
      return status[arg];
    }
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    // sessionStorage.removeItem("locks");
  }
};
</script>
